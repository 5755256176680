import React, {useState} from 'react';
import 'leaflet/dist/leaflet.css';
import './SpotPopup.css';
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import PaymentType from "../model/PaymentType";
import CartEntry from "../model/CartEntry";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {differenceInDays} from "date-fns";
import SpotDetails from "../model/SpotDetails";
import {useSpotStore} from "../store/SpotStore";
import {shallow} from "zustand/shallow";

const SpotPopup = () => {
    const [showSpot, closeSpot] = useSpotStore(state => [state.showSpot, state.closeSpot], shallow)
    const spot = useSpotStore(state => state.spot)
    const [perDayCalendar, setPerDayCalendar] = useSpotStore(state => [state.perDayCalendar, state.setPerDayCalendar], shallow)
    const [paymentType, setPaymentType] = useSpotStore(state => [state.paymentType, state.setPaymentType], shallow)
    const [startDate, setStartDate] = useSpotStore(state => [state.startDate, state.setStartDate], shallow)
    const [endDate, setEndDate] = useSpotStore(state => [state.endDate, state.setEndDate], shallow)

    const handleChange = (e: any) => {
        e.persist();
        switch (+e.target.value) {
            case PaymentType.Yearly:
                setPerDayCalendar(false);
                setPaymentType(PaymentType.Yearly);
                break
            case PaymentType.Monthly:
                setPerDayCalendar(false);
                setPaymentType(PaymentType.Monthly);
                break
            case PaymentType.Weekly:
                setPerDayCalendar(false);
                setPaymentType(PaymentType.Weekly);
                break
            case PaymentType.Once:
                setPerDayCalendar(false);
                setPaymentType(PaymentType.Once);
                break
            case PaymentType.PerDay:
                setPerDayCalendar(true);
                setPaymentType(PaymentType.PerDay);
                break
        }
    }

    const calculatePrice = (paymentType: PaymentType, spot?: SpotDetails): number => {
        let price = 0
        if (spot) {
            switch (+paymentType) {
                case PaymentType.Yearly:
                    price = spot.price.yearly;
                    break;
                case PaymentType.Monthly:
                    price = spot.price.monthly;
                    break;
                case PaymentType.Weekly:
                    price = spot.price.weekly;
                    break;
                case PaymentType.Once:
                    price = spot.price.once;
                    break;
                case PaymentType.PerDay:
                    const daysDifference = differenceInDays(endDate, startDate);
                    price = daysDifference * spot.price.perDay;
                    break;
            }
        }
        return price;
    }

    const onDateChange = (dates: [Date, Date]) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        if (spot) {
            let basketEntry: CartEntry = {
                lakeId: spot.spotId,
                lakeName: spot.title,
                paymentType: paymentType,
                price: calculatePrice(paymentType, spot),
                startDate: startDate,
                endDate: endDate
            }
            let storageBasket = localStorage.getItem('basket');
            let basket: Map<number, CartEntry>
            if (storageBasket == null) {
                basket = new Map();
            } else {
                basket = new Map(JSON.parse(localStorage.getItem('basket') || '{}'));
            }
            basket.set(spot.spotId, basketEntry);
            localStorage.setItem('basket', JSON.stringify(Array.from(basket.entries())));
        }
        closeSpot();
    }

    const handleClose = () => {
        closeSpot();
    }

    return (
        <div>
            <Modal show={showSpot} onHide={closeSpot}>
                <Modal.Header>
                    <Modal.Title>{spot?.title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p id={'description-field'}>{spot?.description}</p>
                    <br/>
                    <p>Rodzaj płatności:</p>
                    <Form>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Check
                                        type={'radio'}
                                        label={'Całoroczne'}
                                        id={'yearly'}
                                        value={PaymentType.Yearly}
                                        checked={paymentType === PaymentType.Yearly}
                                        onChange={handleChange}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Check
                                        type={'radio'}
                                        label={'Miesięczne'}
                                        id={'monthly'}
                                        value={PaymentType.Monthly}
                                        checked={paymentType === PaymentType.Monthly}
                                        onChange={handleChange}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Check
                                        type={'radio'}
                                        label={'Tygodniowe'}
                                        id={'weekly'}
                                        value={PaymentType.Weekly}
                                        checked={paymentType === PaymentType.Weekly}
                                        onChange={handleChange}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Check
                                        type={'radio'}
                                        label={'Jednorazowe'}
                                        id={'once'}
                                        value={PaymentType.Once}
                                        checked={paymentType === PaymentType.Once}
                                        onChange={handleChange}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={'auto'}>
                                    <Form.Check
                                        type={'radio'}
                                        label={'Wybierz przedział'}
                                        id={'perDay'}
                                        value={PaymentType.PerDay}
                                        checked={paymentType === PaymentType.PerDay}
                                        onChange={handleChange}/>
                                </Col>
                                <Col>
                                    {perDayCalendar &&
                                        <DatePicker
                                            selected={startDate}
                                            onChange={onDateChange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            dateFormat={'dd-MM-yyyy'}
                                            selectsRange
                                        />
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" className={'mr-2'} onClick={handleClose}>
                        Zamknij
                    </Button>
                    <Button variant="primary" className={'mx-2'} onClick={handleSubmit}>
                        Dodaj do koszyka
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default SpotPopup;