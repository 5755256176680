import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {CloseButton, Col, Container, Row, Table} from "react-bootstrap";
import CartEntry from "../model/CartEntry";
import PaymentType from "../model/PaymentType";
import {useState} from "react";
import {format, parseISO} from "date-fns";

function Payment() {

    let storageBasket = localStorage.getItem('basket');

    let basketMap: Map<number, CartEntry>

    if (storageBasket == null) {
        basketMap = new Map();
    } else {
        basketMap = new Map(JSON.parse(localStorage.getItem('basket') || '{}'));
    }

    const [basket, setBasket] = useState(Array.from(basketMap.values()))

    const sum: number = basket.map(basketEntry => basketEntry.price).reduce((sum, current) => sum + current, 0);

    const removeEntry = (lakeId: number) => {
        basketMap.delete(lakeId);
        setBasket(Array.from(basketMap.values()));
        localStorage.setItem('basket', JSON.stringify(Array.from(basketMap.entries())));
    }

    const getPaymentType = (basketEntry: CartEntry) => {
        switch (+basketEntry.paymentType) {
            case PaymentType.Yearly:
                return 'Rocznie'
            case PaymentType.Monthly:
                return 'Miesięcznie'
            case PaymentType.Weekly:
                return 'Tygodniowo'
            case PaymentType.Once:
                return 'Jednorazowo'
            case PaymentType.PerDay:
                return 'Od ' + format(new Date(basketEntry.startDate), 'dd-MM-yyyy') + ' do ' + format(new Date(basketEntry.endDate), 'dd-MM-yyyy')
        }
    }

    return (
        <>
            <Form>
                <Container className={'mt-3'} fluid>
                    <Row>
                        <Col xs={0} md={4}/>
                        <Col xs={12} md={4}>
                            <h2>Podsumowanie zamówienia</h2>

                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Nazwa</th>
                                        <th>Rodzaj płatności</th>
                                        <th>Cena</th>
                                        <th>Usuń</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {basket.map(basketEntry =>
                                    <tr>
                                        <th>{basketEntry.lakeName}</th>
                                        <th>{getPaymentType(basketEntry)}</th>
                                        <th>{basketEntry.price} zł</th>
                                        <th><CloseButton onClick={() => removeEntry(basketEntry.lakeId)}/></th>
                                    </tr>
                                )}
                                </tbody>
                            </Table>

                            <p><b>Łącznie do zapłaty: {sum} zł</b></p>

                            <br/>

                            <h3>Twoje dane</h3>

                            <Form.Group className="mb-3" controlId="formFirstName">
                                <Form.Label>Imię</Form.Label>
                                <Form.Control type="text" placeholder="Wprowadź imię"/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formLastName">
                                <Form.Label>Nazwisko</Form.Label>
                                <Form.Control type="text" placeholder="Wprowadź nazwisko"/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formEmail">
                                <Form.Label>Adres email</Form.Label>
                                <Form.Control type="email" placeholder="Wprowadź adres email"/>
                                <Form.Text className="text-muted">
                                    Na podany adres email otrzymasz pozwolenie
                                </Form.Text>
                            </Form.Group>

                            <Button variant="primary" type="submit">
                                Przejdź do płatności
                            </Button>
                        </Col>
                        <Col xs={0} md={4}/>
                    </Row>
                </Container>
            </Form>
        </>
    );
}

export default Payment;