import {create} from "zustand";
import type {User} from "netlify-identity-widget";
import {createJSONStorage, persist} from "zustand/middleware";

interface AuthState {
    user: User | undefined
    setUser: (user?: User) => void
    isSpotManager: boolean
}

export const useAuthStore = create<AuthState>()(persist((set) => ({
    user: undefined,
    setUser: (user) => set(() => ({
        user: user,
        isSpotManager: user?.app_metadata?.roles?.includes('spot_manager')
    })),
    isSpotManager: false
}), {
    name: 'auth-storage',
    storage: createJSONStorage(() => localStorage)
}))