import React from 'react';
import './Navigation.css';
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {GiBoatFishing} from "react-icons/gi";
import {BsCart4} from "react-icons/bs";
import {useAuthStore} from "../store/AuthStore";
import netlifyIdentity from "netlify-identity-widget";

function Navigation() {
    const user = useAuthStore(state => state.user)
    const isSpotManager = useAuthStore(state => state.isSpotManager)

    const login = () => {
        netlifyIdentity.open()
    }

    const logout = () => {
        netlifyIdentity.logout()
    }

    return (
        <Navbar id={'navbar'} expand="lg" className="bg-body-tertiary sticky-top">
            <Container fluid="lg">
                <Navbar.Brand href="/">Pozwolenia wędkarskie</Navbar.Brand>
                <Navbar.Toggle/>
                <Navbar.Collapse className="justify-content-end">
                    <Nav>
                        {!user ? (
                            <Nav.Item>
                                <Nav.Link bsPrefix={'btn btn-primary mx-2 my-1 shadow'} onClick={login}>Zaloguj
                                    się <GiBoatFishing
                                        size={20}/></Nav.Link>
                            </Nav.Item>
                        ) : isSpotManager ? (
                            <Nav.Item>
                                <NavDropdown bsPrefix={'btn btn-primary mx-2 my-1 shadow'}
                                             title={<div style={{display: "inline-block"}}>Moje konto <GiBoatFishing
                                                 size={20}/></div>} id={'basic-nav-dropdown'}>
                                    <NavDropdown.Item>Zarządzaj punktami</NavDropdown.Item>
                                    <NavDropdown.Item onClick={logout}>Wyloguj się</NavDropdown.Item>
                                </NavDropdown>
                            </Nav.Item>
                        ) : (
                            <Nav.Item>
                                <NavDropdown bsPrefix={'btn btn-primary mx-2 my-1 shadow'}
                                             title={<div style={{display: "inline-block"}}>Moje konto <GiBoatFishing
                                                 size={20}/></div>} id={'basic-nav-dropdown'}>
                                    <NavDropdown.Item>Dane użytkownika</NavDropdown.Item>
                                    <NavDropdown.Item>Moje pozwolenia</NavDropdown.Item>
                                    <NavDropdown.Item>Sprawdź lokalizację</NavDropdown.Item>
                                    <NavDropdown.Item>Historia transakcji</NavDropdown.Item>
                                    <NavDropdown.Item onClick={logout}>Wyloguj się</NavDropdown.Item>
                                </NavDropdown>
                            </Nav.Item>
                        )
                        }
                        <Nav.Item>
                            <Nav.Link bsPrefix={'btn btn-primary mx-2 my-1 shadow'} href="/basket">Koszyk <BsCart4
                                size={20}/></Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;
