import {create} from "zustand";
import type SpotDetails from "../model/SpotDetails";
import PaymentType from "../model/PaymentType";
import api from "../api/api";

interface SpotState {
    showSpot: boolean
    spot: SpotDetails | undefined
    paymentType: PaymentType
    perDayCalendar: boolean
    startDate: Date
    endDate: Date
    closeSpot: () => void
    fetchSpot: (spotId: number) => void
    setPaymentType: (paymentType: PaymentType) => void
    setPerDayCalendar: (show: boolean) => void
    setStartDate: (date: Date) => void
    setEndDate: (date: Date) => void
}

export const useSpotStore = create<SpotState>()((set) => ({
    showSpot: false,
    spot: undefined,
    paymentType: PaymentType.Yearly,
    perDayCalendar: false,
    startDate: new Date(),
    endDate: new Date(),
    closeSpot: () => set(() => ({
        showSpot: false,
        perDayCalendar: false,
        startDate: new Date(),
        endDate: new Date()
    })),
    fetchSpot: async (spotId) => {
        await api.get<SpotDetails>(`/.netlify/functions/spot-details/${spotId}`)
            .then(spotDetails => set({spot: spotDetails, showSpot: true}))
            .catch(() => {
                console.log(`Error when fetching spot details.`)
            })
    },
    setPaymentType: (paymentType) => set(() => ({
        paymentType: paymentType
    })),
    setPerDayCalendar: (show) => set(() => ({
        perDayCalendar: show
    })),
    setStartDate: (date) => set(() => ({
        startDate: date
    })),
    setEndDate: (date) => set(() => ({
        endDate: date
    }))
}))