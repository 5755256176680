import React, {useEffect} from 'react';
import 'leaflet/dist/leaflet.css';
import {MapContainer, Polygon, TileLayer} from 'react-leaflet'
import './SpotMap.css';
import SpotPopup from "./SpotPopup";
import {useSpotStore} from "../store/SpotStore";
import {useSpotListStore} from "../store/SpotListStore";
import {generateUniqueID} from "web-vitals/dist/modules/lib/generateUniqueID";

function SpotMap() {
    const fetchSpot = useSpotStore(state => state.fetchSpot)
    const spots = useSpotListStore(state => state.spots)

    const handleShow = (spotId: number) => {
        fetchSpot(spotId)
    }

    let navbarHeight = Number(localStorage.getItem('navbarHeight'))

    const style = {
        height: `calc(100vh - ${navbarHeight}px)`
    };

    return (
        <div style={style}>
            <MapContainer id="lakeMap" center={[54.2482, 18.6967]} zoom={11} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {spots.map(spot =>
                    spot.positions.map(position =>
                        <Polygon key={generateUniqueID()} positions={position} eventHandlers={{
                            click: () => {
                                handleShow(spot.spotId)
                            }
                        }}/>
                    )
                )}
                <SpotPopup/>
            </MapContainer>
        </div>
    );
}

export default SpotMap;