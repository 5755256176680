import React, {useEffect, useRef} from 'react';
import './App.css';
import SpotMap from "./component/SpotMap";
import Navigation from "./component/Navigation";
import {Route, Routes} from "react-router-dom";
import Payment from "./component/Payment";
import netlifyIdentity, {User} from "netlify-identity-widget";
import {useAuthStore} from "./store/AuthStore";

const App = () => {
    const setUser = useAuthStore(state => state.setUser)

    const navbarRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (navbarRef.current) {
            const height = navbarRef.current.offsetHeight;
            localStorage.setItem('navbarHeight', height.toString());
        }
    }, []);

    useEffect(() => {
        netlifyIdentity.init({
            locale: 'pl',
            namePlaceholder: 'Imię i nazwisko'
        })
    })

    useEffect(() => {
        netlifyIdentity.on('init', (user: User | null) => {
            if (user) {
                setUser(user)
            }
        })
        netlifyIdentity.on('login', (user: User) => {
            setUser(user)
            netlifyIdentity.close()
            console.log(user)
        })
        netlifyIdentity.on('logout', () => {
            setUser(undefined)
        })
    })

    return (
        <>
            <div ref={navbarRef}>
                <Navigation/>
            </div>
            <Routes>
                <Route path="/" element={<SpotMap/>}/>
                <Route path="/basket" element={<Payment/>}/>
            </Routes>
        </>
    );
}

export default App;
