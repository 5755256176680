import type Spot from "../model/Spot";
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

const spotsData: Spot[] = require('../data/spots.json')

interface SpotListState {
    spots: Spot[]
}

export const useSpotListStore = create<SpotListState>()(persist((set) => ({
    spots: spotsData
}), {
    name: "spot-list-storage",
    storage: createJSONStorage(() => localStorage)
}))